import { Box } from '@material-ui/core';
import { Modal } from 'antd';
import { images } from 'assets/images';
import { Fragment, useEffect, useState } from 'react';
import { checkoutService } from 'services/lootbox/checkout';
import './explore.scss';
import { lbprofileAction } from "actions/lootbox/profile";
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import netvrk_1 from '../../assets/images/netvrk-v2-1.jpg';
import netvrk_2 from '../../assets/images/netvrk-v2-2.jpg';
import netvrk_3 from '../../assets/images/netvrk-v2-3.jpg';
import netvrk_4 from '../../assets/images/netvrk-v2-4.jpg';
import netvrk_5 from '../../assets/images/netvrk-v2-5.jpg';
import netvrk_6 from '../../assets/images/netvrk-v2-6.jpg';
import netvrk_7 from '../../assets/images/netvrk-v2-7.jpg';
import netvrk_8 from '../../assets/images/netvrk-v2-8.jpg';
import netvrk_9 from '../../assets/images/netvrk-v2-9.jpg';
import netvrk_10 from '../../assets/images/netvrk-v2-10.jpg';



const defaultLe = ["Avatar","Bonus Pack NFTs","Epic","Giant","Medium","Standard"];

const Explore = () => {
    const { t, i18n } = useTranslation('common');
    const [showModal, setShowModal] = useState(false);
  const [userJwt, setUserJwt] = useState(null);
  const [levelUser, setLevelUser] = useState(null);
  const [level,setLevel]= useState(null)
  const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);
  const history = useHistory();
  useEffect(async() => {
    const level = await checkoutService.getUserLever();
    if(level.level_infor){
      setLevelUser(level.level_infor);
    }
    setShowModal(true);
  }, []);
  useEffect(async()=>{
    if(isLoggedIn){
      const level = await checkoutService.getUserLever();
      if(level.level_infor){
        setLevel(level)
        setLevelUser(level.level_infor);
      }
      setShowModal(true)
    }
  },[isLoggedIn])
  const handleClickConnect = () => {
    if(isLoggedIn){
      lbprofileAction.showModalExplore(true)
      history.push("/launchpad")
    }else{
      lbprofileAction.connect();
    }
  };
  const modalNotify = () => {
    return (
      <Modal
        width={600}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0, margin: 0 }}
        className='modal-welcome'
      >
        {/* <img src={trans} width='100%' /> */}
        <div className='mt-5 ml-2 mr-2 mb-5'>

          <h2 style={{ textAlign: 'center', fontWeight: 600 }}>{t('welcome.welcome')}</h2>
          {levelUser && levelUser.id != 8 && <Fragment>
            <div className='d-flex justify-content-center'>
            <span style={{ color: '#6B718C', fontSize: 20, fontWeight: 600 }}>
              {t('welcome.youare')} <span style={{ color: '#F34F24' }}>{t('welcome.level')} {levelUser && levelUser.id != 7 ? levelUser.id : "0"}</span>

            </span>
          </div></Fragment>} 
          {levelUser && levelUser.id != 8 && <div style={{ backgroundColor: '#F4F4F7' }} className='rule-user ml-2 mr-2 mt-5 mb-5'>
            <span
            className="item"
              style={{
                color: '#17204D',
                fontSize: 16,
                fontWeight: 600,
                textAlign: 'center',
                display: 'table',
                margin: '0 auto',
              }}
            >
             {t('welcome.youcanbuy')}
            </span>
            {levelUser&& levelUser.product_can_buy && levelUser.product_can_buy.map((m,key) =>{
              return <span key={m.id} className="item" style={{ color: '#17204D', fontSize: 16, fontWeight: 600, display: 'table', margin: '0' }}>
              {m.max_amount} {m.name}
             </span>
            })}
          </div>}
          <div className='d-flex justify-content-center pb-5'>
            <div
              style={{
                width: 360,
                height: 60,
                backgroundImage: 'linear-gradient(#c39a2f, #ebd6a1)',
                cursor: 'pointer',
                color: '#4d3700',
                fontWeight: 700,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              role='button'
              onClick={()=>{history.push("/launchpad")}}
            >
              <span>{t('welcome.gotit')}</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <Box className='home row' style={{background:"#000"}}>
      {/* {modalNotify()} */}
      <h1 className='col-12 d-flex justify-content-center'>SPORES x NETVRK</h1>
      <h3 className='col-12 d-flex justify-content-center'>{t('explorer.virtuallandsale')}</h3>
      <div className='col-md-6 col-xs-12 d-flex justify-content-center description'>{t('explorer.amet')}
      </div>
      <div className='col-12 d-flex justify-content-center'>
        <div className='button' role='button' onClick={()=>{handleClickConnect()}} >
          {level && level.message == 0? <span>{t('explorer.participate')}</span>: <span>{t('explorer.participate')}</span>}
          

        </div>
      </div>
      <div className='col-12 list-image'>
        <img src={netvrk_1} />
        <img src={netvrk_2} />
        <img src={netvrk_3} />
        <img src={netvrk_4} />
        <img src={netvrk_5} />
        <img src={netvrk_6} />
        <img src={netvrk_7} />
        <img src={netvrk_8} />
        <img src={netvrk_9} />
        <img src={netvrk_10} />
      </div>
    </Box>
  );
};

export default Explore;
