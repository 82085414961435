/* eslint-disable jsx-a11y/alt-text */
import { Fragment, useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CountDown from 'components/CountDown';
import { Card, List } from 'antd';
import { images } from 'assets/images';
import ItemLaunchpad from './ItemLaunchpad';
import { useTranslation } from 'react-i18next';
import { productService } from 'services/lootbox/product';
import { checkoutService } from 'services/lootbox/checkout';
import trans from 'assets/images/trans.png';
import { Modal } from 'antd';
import netvrk_1 from '../../assets/images/netvrk-v2-1.jpg';
import netvrk_2 from '../../assets/images/netvrk-v2-2.jpg';
import netvrk_3 from '../../assets/images/netvrk-v2-3.jpg';
import netvrk_4 from '../../assets/images/netvrk-v2-4.jpg';
import netvrk_5 from '../../assets/images/netvrk-v2-5.jpg';
import netvrk_6 from '../../assets/images/netvrk-v2-6.jpg';
import netvrk_7 from '../../assets/images/netvrk-v2-7.jpg';
import netvrk_8 from '../../assets/images/netvrk-v2-8.jpg';
import netvrk_9 from '../../assets/images/netvrk-v2-9.jpg';
import netvrk_10 from '../../assets/images/netvrk-v2-10.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { lbprofileAction } from "actions/lootbox/profile";
export default function Launchpad() {
  const [itemCampain] = useState({});
  const [products, setProducts] = useState([]);
  const [landItem, setLandItem] = useState([]);
  const [avatarItem, setAvatarItem] = useState([]);
  const { t, i18n } = useTranslation('common');
  const [useLevel, setUserLevel] = useState(null);
  const history = useHistory();
  const { showModalExplore } = useSelector(({ profileLootbox }) => profileLootbox);
  const [levelUser, setLevelUser] = useState(null);
  
  useEffect(async () => {
    const res = await checkoutService.getUserLever();
    setUserLevel(res);
    setLevelUser(res.level_infor);
  }, []);
  function swap(arr, from, to) {
    arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
  }
  const modalNotify = () => {
    return (
      <Modal
        width={600}
        visible={showModalExplore}
        onCancel={() => lbprofileAction.showModalExplore(false)}
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0, margin: 0 }}
        className='modal-welcome'
      >
        <img src={trans} width='100%' />
        <div className='mt-5 ml-2 mr-2 mb-5'>

          <h2 style={{ textAlign: 'center', fontWeight: 600 }}>{t('welcome.welcome')}</h2>
          {levelUser && levelUser.id != 9 && <Fragment>
            <div className='d-flex justify-content-center'>
            <span style={{ color: '#6B718C', fontSize: 20, fontWeight: 600 }}>
              {t('welcome.youare')} <span style={{ color: '#F34F24' }}>{t('welcome.level')} {levelUser && levelUser.id}</span>

            </span>
          </div></Fragment>} 
          {levelUser && levelUser.id != 9 && <div style={{ backgroundColor: '#F4F4F7' }} className='rule-user ml-2 mr-2 mt-5 mb-5'>
            <span
            className="item"
              style={{
                color: '#17204D',
                fontSize: 16,
                fontWeight: 600,
                textAlign: 'center',
                display: 'table',
                margin: '0 auto',
              }}
            >
             {t('welcome.youcanbuy')}
            </span>
            {levelUser && levelUser.product_can_buy && levelUser.product_can_buy.map((m,key) =>{
              return <span key={m.id} className="item" style={{ color: '#17204D', fontSize: 16, fontWeight: 600, display: 'table', margin: '0' }}>
              {m.max_amount} {m.name}
             </span>
            })}
          </div>}
          <div className='d-flex justify-content-center pb-5'>
            <div
              style={{
                width: 360,
                height: 60,
                backgroundImage: 'linear-gradient(#c39a2f, #ebd6a1)',
                cursor: 'pointer',
                color: '#4d3700',
                fontWeight: 700,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              role='button'
              onClick={()=>{lbprofileAction.showModalExplore(false)}}
            >
              <span>{t('welcome.gotit')}</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  useEffect(async () => {
    const res = await productService.product();
    const productres = [...res.data].reverse();
    const land = productres.filter((f) => f.product.product_type == 1);
    const avatar = productres.filter((f) => f.product.product_type != 1);
    if (land != null && land.length > 0) {
      const sort = land.sort((a,b)=>{return (b.product.price - a.product.price)})
      setLandItem(sort);
    }
    if (avatar != null && avatar.length > 0) {
      swap(avatar, 1, 2);
      swap(avatar, 0, 1);
      const sort = avatar.sort((a,b)=>{return (b.product.price - a.product.price)})
      setAvatarItem([...sort]);
    }
    setProducts(productres);
  }, []);
  let pageStyle = {
    width: '100%', position: 'relative', float: 'left', backgroundColor: '#E5E5E5'
  };
  return (
    <div style={{...pageStyle}}>
      {modalNotify()}
      <div className='campain-page launchpad-container'>
        <div className='banner'>
          <CountDown data={itemCampain} />
        </div>
        <div className='box-virtual mt-2'>
          <Card>
            <h1>{t('launchpad.virtualland')}</h1>
            <div className='list-item'>
              <List
                grid={{ gutter: [24, 8], column: 2, xs: 1, sm: 1, md: 2, lg: 2 }}
                dataSource={landItem}
                renderItem={(item) => (
                  <List.Item>
                    <ItemLaunchpad useLevel={useLevel} products={item} title='virtual' />
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </div>
        {/* <div className='box-virtual mt-2'>
          <Card>
            <h1>{t('launchpad.avatars_bonus_pack')}</h1>
            <div className='list-item'>
              <List
                grid={{ gutter: [24, 8], column: 2, xs: 1, sm: 1, md: 2, lg: 2 }}
                dataSource={avatarItem}
                renderItem={(item) => (
                  <List.Item>
                    <ItemLaunchpad useLevel={useLevel} products={item} title='avatar' />
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </div> */}
      </div>
    </div>
  );
}
