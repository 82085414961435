import { Explore, ExploreAuction } from 'views/Explore';
import { ExploreSearch } from 'views/ExploreSearch';
import { History } from 'views/History';
import Launchpad from 'views/Launchpad/Launchpad';
import { Profile } from 'views/Profile';
import { ProfileUpdate } from 'views/ProfileUpdate';
import Cart from 'views/Cart';
import Submit from 'views/Cart/Submit';

const privateRoute = {
  home: {
    path: '/',
    component: Explore,
  },

  explore: {
    path: '/explore',
    component: Explore,
  },
  launchpad :{
    path :'/launchpad',
    component: Launchpad,
    requiredLogin: true,
  },
  // exploreAuction: {
  //   path: '/explore/auction',
  //   component: ExploreAuction,
  // },
  // search: {
  //   path: '/explore/search',
  //   component: ExploreSearch,
  // },

 
  profileUpdate: {
    path: '/profile/update',
    component: ProfileUpdate,
    requiredLogin: true,
  },
  profile: {
    path: '/profile',
    component: Profile,
    requiredLogin: true,
  },

  artist: {
    path: '/artist/:id',
    url: (id) => `/artist/${id}`,
    component: Profile,
  },
  history: {
    path: '/history',
    requiredLogin: true,
    component: History,
  },
  submit: {
    path: '/cart/:id/:type',
    url: (id,type) => `/cart/${id}/${type}`,
    component: Submit,
    requiredLogin: true,
  },
  cart: {
    path: '/cart',
    requiredLogin: true,
    component: Cart
  }
};

export default privateRoute;
